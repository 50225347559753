import {
  Box,
  Button,
  Flex,
  Img,
  Spacer,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import chakraHero from '../assets/ss.png';
import { FaRocket, FaRocketchat } from 'react-icons/fa';
import bgbg from '../assets/bg-intro-mobile.svg';


const Hero = () => {
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  return (
    <Flex
      alignItems="center"
      w="full"
      px={isLargerThanLG ? '16' : '6'}
      py="16"
      minHeight="90vh"
      justifyContent="space-between"
      flexDirection={isLargerThanLG ? 'row' : 'column'}
    >
      <Box mr={isLargerThanLG ? '6' : '0'} w={isLargerThanLG ? '60%' : 'full'}>
        <Text
          fontSize={isLargerThanLG ? '5xl' : '4xl'}
          fontWeight="bold"
          mb="-4"
         
        >
          
          This is
        </Text>
         <Text
          fontSize={isLargerThanLG ? '5xl' : '4xl'}
          fontWeight="bold"
          mb="4"
          color='green'
        >
          
          Escrow Board
        </Text>

        <Text mb="6" fontSize={isLargerThanLG ? 'lg' : 'base'} opacity={0.7}>
A decentralized escrow payment system managed by the community is an innovative approach that harnesses the power of blockchain technology to create a secure and transparent method for facilitating transactions. Traditionally, escrow services have been provided by centralized entities, such as banks or legal firms, but a decentralized system shifts the control and responsibility to a community of participants.        </Text>
 <a href='http://app.escrowboard.xyz/' >
  <Button
          w="200px"
          colorScheme="green"
          variant="solid"
          h="50px"
          leftIcon={ <FaRocket></FaRocket> }
          
        >
           Launch   
          </Button>
          </a> 
      </Box>
      <Spacer />
      <Flex
        w={isLargerThanLG ? '40%' : 'full'}
        alignItems="center"
        justifyContent="center"
      >
        <Img style={ { height: "500px", borderRadius: "10px", border: "solid", borderColor: "green" } } src={ chakraHero } />
      </Flex>
    </Flex>
    
  );
};

export default Hero;