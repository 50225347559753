import { Flex, Text, Link, Img } from '@chakra-ui/react';
import React from 'react';
import Logo from '../assets/logo-full.png';

const Footer = () => {
  return (
    <Flex
      w="full"
      bg="blackAlpha.50"
      minHeight="10vh"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
    >
                    <Img width={200} src={Logo} alt="Chakra UI" />

    </Flex>
  );
};

export default Footer;