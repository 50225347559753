import { Flex, Spacer, Text, useMediaQuery } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { FaTools, FaHandshake, FaStar, FaPeopleCarry, FaPeopleArrows, FaSquare, FaJsSquare, FaAtom } from 'react-icons/fa';
import React from 'react';

const AboutUs = () => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const array = [
    {
      id: 1,
      text: ' Solving world problems through various web applications using efficient programs and tools',
      title:'No Middle Man',
      icon: FaPeopleArrows,
    },
    {
      id: 2,
      text: 'Fully decentralized and operated by smart contracts.',
            title:'100% Decentralized',

      icon: FaAtom,
    },
    {
      id: 3,
      text: 'Whenever a dispute arise, the community review and decide the outcome.',
            title:'Community Managed',

      icon: FaPeopleCarry,
    },
  ];
  return (

    <>
          <Text  ml={"70px"} fontSize={'5xl'} color={"green"} fontWeight={'bold'}>Why Choose Escrow Board?</Text>
          <Text marginLeft={20}marginRight={20}  ml={"70px"} >First Decentralized Escrow Payment System managed by the Community. Buyer and sellers can create a dispute and the community will review the case and vote on the outcome

</Text>


      <Flex
        minH="70vh"
        alignItems="center"
        justifyContent="space-between"
        w="full"
       mt={"-80px"}
        px={isLargerThanMD ? '16' : '6'}
        flexWrap="wrap"
        flexDirection={isLargerThanMD ? 'row' : 'column'}
      >
        {array.map((arr) => (
          <>
            <Flex
              height="300px"
              bg="green"
              width={ isLargerThanMD ? '32%' : 'full' }
              color={'white'}
              shadow="md"
              p="6"
              alignItems="center"
              justifyContent="center"
              borderRadius="md"
              flexDirection="column"
              textAlign="center"
              mb={isLargerThanMD ? '0' : '4'}
              border="1px solid #C4DDFF"
            >
              <Icon as={ arr.icon } boxSize={ 14 } color="white" mb="5" />
              <Text fontSize={'3xl'} fontWeight={'semibold'}>{ arr.title}</Text>
              <Text>{arr.text}</Text>
            </Flex>

            <Spacer />
          </>
        ))}
      </Flex>
    </>
  );
};

export default AboutUs;